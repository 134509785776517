<template>
  <div
    class="
      rn-overlay
      fixed
      inset-0
      bg-secondary-900
      duration-200
      transition-all
    "
    :class="{
      'rn-visible': open,
      [className]: true,
    }"
    @click="$emit('close')"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    class: {
      type: String,
      default: () => '',
    },
  },
  emits: ['close'],
  computed: {
    className() {
      return this.class
    },
  },
})
</script>

<style scoped>
/* https://codepen.io/williamliaw/pen/gPMdrm */

/** impossible to add this using tailwind */
.rn-overlay {
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.rn-overlay.rn-visible {
  opacity: 0.5;
  visibility: visible;
}
</style>
