<template>
  <div
    class="
      font-extrabold font-montserrat
      text-secondary-700
      uppercase
      text-sm
      pb-3
    "
    :class="className"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapClassName } from '@/utils/classNames'

export default defineComponent({
  props: {
    class: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapClassName(),
  },
})
</script>
