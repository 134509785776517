<template>
  <div class="font-montserrat p-5 md:p-4" :class="className">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { mapClassName } from '@/utils/classNames'

export default defineComponent({
  props: {
    class: {
      type: String,
      default: () => '',
    },
    large: {},
  },
  computed: {
    ...mapClassName(),
  },
})
</script>
