<template>
  <tooltip :title="title" :disabled="!title" :placement="tooltipPlacement">
    <button
      class="leading-0"
      :class="classNames"
      :disabled="disabled"
      @click="onClick"
    >
      <icon :icon="icon" :class="iconClass" :size="size" />
      <slot />
    </button>
  </tooltip>
</template>

<script>
import { defineComponent } from 'vue'

import Icon from '@/components/atoms/Icon'
import Tooltip from '@/components/atoms/Tooltip'

export default defineComponent({
  name: 'IconButton',
  components: {
    Icon,
    Tooltip,
  },
  emits: ['click'],
  props: {
    class: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom',
    },
    icon: {
      type: String,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value) {
        return ['small', 'medium', 'fat', 'custom'].indexOf(value) !== -1
      },
      default: 'custom',
    },
  },
  computed: {
    classNames: function () {
      return {
        [this.class]: true,
        'opacity-50': this.disabled,
        'cursor-pointer': !this.disabled,
        'text-secondary-700': this.primary && !this.active,
        'hover:text-primary': this.primary && !this.active && !this.disabled,
        'text-primary': this.primary && this.active,
      }
    },
  },
  methods: {
    onClick(event) {
      if (!this.disabled) this.$emit('click', event)
    },
  },
})
</script>
