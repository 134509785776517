/**
 * vue.js can't use property called `class` so you have to rename the property
 * works as `mapGetters` :
 * ````
 * computed: {
 *   ...mapClassName()
 * }
 * ```
 *
 * and then, you can just use `this.className`
 */
export function mapClassName() {
  return {
    className() {
      return this.class
    },
  }
}
