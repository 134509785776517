<template>
  <background-overlay :open="open" @close="close" class="md:hidden" />
  <div
    class="
      rn-popup
      fixed
      inset-x-0
      bg-secondary-100
      rounded-tl-md rounded-tr-md
      md:hidden
    "
    :class="className"
    v-on:touchmove="touchMove"
    v-on:touchend="touchEnd"
    :style="style"
    ref="bottomSheet"
  >
    <div class="py-2 flex justify-center" v-if="slidable">
      <div class="rounded w-24 h-1 bg-secondary-500" />
    </div>
    <slot />
  </div>
  <div
    class="
      rn-popup-hint
      block
      fixed
      inset-x-0
      bottom-0
      md:hidden
      text-center
      font-montserrat font-semibold
      text-sm
    "
    v-if="open && shouldShowHint && slidable"
  >
    <div class="h-2 bg-gradient-to-b from-transparent to-primary"></div>
    <div class="bg-primary text-secondary-100 p-0.5">
      <div>{{ $t('global.bottomSheetSwipeUp') }}</div>
      <div>{{ $t('global.bottomSheetSwipeDown') }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import anime from 'animejs/lib/anime.es.js'
import { App } from '@capacitor/app'
import { mapMutations, mapGetters } from 'vuex'
import { useBackButton } from '@ionic/vue'

import BackgroundOverlay from '@/components/atoms/BackgroundOverlay'

const screenHeight = () => screen.height

const expandBottonSheetAction = 'expandBottonSheetAction'
const hideBottonSheetAction = 'hideBottonSheetAction'

export default defineComponent({
  name: 'BottomSheet',
  components: {
    BackgroundOverlay,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    slidable: {
      type: Boolean,
      default: () => false,
    },
    class: {
      type: String,
      default: () => '',
    },
  },
  emits: ['close'],
  computed: {
    className() {
      return `${this.class} ${
        this.slidable ? 'h-screen rn-slidable' : 'bottom-0 rn-fixed'
      } ${this.top === 0 && this.slidable ? 'overflow-auto' : ''}`
    },
    style() {
      return this.slidable
        ? { top: `${this.top}px` }
        : { marginBottom: `${this.marginBottom}px` }
    },
    shouldShowHint() {
      return (
        this.getActionStat(expandBottonSheetAction) === 0 ||
        this.getActionStat(hideBottonSheetAction) === 0
      )
    },
    ...mapGetters(['getActionStat']),
  },
  data() {
    return {
      top: screenHeight(),
      previousTop: screenHeight(),
      marginBottom: -2000,
      initialPositionY: null,
      middleTop: (screenHeight() * 3) / 5,
      isAnimating: false,
    }
  },
  setup() {
    useBackButton(1000, () => {
      console.log('back button')
      if (this.open) {
        this.closeWithoutGoBack()
      }
      console.log('Another handler was called!')
    })
  },
  created() {
    // window.addEventListener('resize', this.closeIfNecessary)
  },
  unmounted() {
    // window.removeEventListener('resize', this.closeIfNecessary)
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(function () {
        if (
          !this.slidable &&
          this.$refs?.bottomSheet &&
          this.$refs.bottomSheet.offsetHeight > 0
        ) {
          this.marginBottom = -this.$refs.bottomSheet.offsetHeight
        }
      })
    }, 2000)

    App.addListener('backButton', () => {
      if (this.open) {
        this.closeWithoutGoBack()
      }
    })
  },
  methods: {
    closeWithoutGoBack() {
      this.$emit('close')
    },
    close() {
      this.closeWithoutGoBack()
      if (this.$route && this.$route?.path.endsWith('/menu')) {
        this.$router.back()
      }
    },
    closeIfNecessary() {
      if (this.open) {
        this.close()
      }
    },
    touchMove(event) {
      if (
        this.slidable &&
        event.touches &&
        event.touches.length > 0 &&
        !this.isAnimating
      ) {
        const positionY = event.touches[0].clientY
        if (this.initialPositionY) {
          const difference = this.initialPositionY - positionY

          let newTop = this.previousTop - difference

          if (newTop >= screenHeight()) {
            newTop = screenHeight()
          }

          if (newTop < 0) {
            newTop = 0
          }

          this.top = newTop
        } else {
          this.initialPositionY = positionY
          this.previousTop = this.top
        }
      }
    },
    swipeUp() {
      return this.previousTop - this.top > screenHeight() / 100
    },
    swipeDown() {
      return this.top - this.previousTop > (3 * screenHeight()) / 100
    },
    swipeDownMoreThanHalf() {
      return this.top - this.previousTop > screenHeight() / 2
    },
    touchEnd() {
      if (this.slidable) {
        if (this.previousTop === this.middleTop) {
          if (this.swipeUp()) {
            this.expandFull()
          } else if (this.swipeDown()) {
            this.minimize()
            this.close()
          } else {
            this.expandHalf()
          }
        } else if (this.previousTop === 0) {
          if (this.swipeDownMoreThanHalf()) {
            this.minimize()
          } else if (this.swipeDown()) {
            this.expandHalf()
          } else {
            this.expandFull()
          }
        }
        this.initialPositionY = null
        this.previousTop = this.top
      }
    },
    expandFull() {
      this.animateSlidable({ top: 0 })
      this.registerAction(expandBottonSheetAction)
    },
    expandHalf() {
      this.animateSlidable({ top: this.middleTop })
    },
    minimize() {
      this.animateSlidable({ top: screenHeight() })
      this.registerAction(hideBottonSheetAction)
    },
    animateSlidable({ top }) {
      this.isAnimating = true
      // https://animejs.com/documentation/
      anime({
        targets: this,
        top,
        easing: 'linear',
        duration: Math.abs(top - this.top) * 0.3,
        complete: () => {
          this.isAnimating = false
        },
      })
    },
    animateFixed({ marginBottom }) {
      this.isAnimating = true
      // https://animejs.com/documentation/
      anime({
        targets: this,
        marginBottom,
        easing: 'linear',
        duration: Math.abs(marginBottom - this.marginBottom) * 0.6,
        complete: () => {
          this.isAnimating = false
        },
      })
    },
    ...mapMutations(['registerAction']),
  },
  watch: {
    open(newOpen) {
      if (!this.$refs?.bottomSheet) {
        return
      }

      if (newOpen && !this.$router.currentRoute.value.path.endsWith('/menu')) {
        this.$router.push(`${this.$router.currentRoute.value.path}/menu`)
      }
      if (this.slidable) {
        if (newOpen) {
          this.expandHalf()
        } else {
          this.minimize()
        }
      } else {
        if (newOpen) {
          this.animateFixed({ marginBottom: 0 })
        } else {
          this.animateFixed({
            marginBottom: -this.$refs.bottomSheet.offsetHeight,
          })
        }
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if (this.open && to && !to?.path.endsWith('/menu')) {
          this.closeWithoutGoBack()
        }

        console.log('isOpen', this.open)
      },
    },
  },
})
</script>
<style scoped>
/* https://codepen.io/williamliaw/pen/gPMdrm */

.rn-popup {
  z-index: 1000;
}

.rn-popup-hint {
  z-index: 3000;
}
</style>
