<template>
  <!-- pointer-events none @see https://github.com/ionic-team/ionicons/issues/949 -->
  <ion-icon :icon="icon" :class="className" class="pointer-events-none" />
</template>

<script>
import { defineComponent } from 'vue'
import { IonIcon } from '@ionic/vue'

export default defineComponent({
  name: 'IconButton',
  components: {
    IonIcon,
  },
  props: {
    class: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
    size: {
      type: String,
      validator: function (value) {
        return ['xs', 'small', 'medium', 'fat', 'custom'].indexOf(value) !== -1
      },
      default: 'custom',
    },
  },
  computed: {
    className: function () {
      return {
        'rn-icon-medium': this.size === 'medium',
        'rn-icon-fat': this.size === 'fat' || this.size === 'xs',
        'text-3xl': this.size === 'fat' || this.size === 'medium',
        'text-2xl': this.size === 'small',
        'text-xl': this.size === 'xs',
        [this.class]: true,
      }
    },
  },
})
</script>

<style scoped>
.rn-icon-medium {
  /* https://ionic.io/ionicons/usage */
  --ionicon-stroke-width: 30px;
}

.rn-icon-fat {
  /* https://ionic.io/ionicons/usage */
  --ionicon-stroke-width: 50px;
}
</style>
