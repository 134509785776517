<template>
  <el-tooltip
    :class="className"
    effect="dark"
    :content="title"
    :placement="placement"
    :disabled="disabled || (!manual && isTouchScreen)"
    :show-after="500"
    :auto-close="4000"
    :manual="manual"
  >
    <slot />
  </el-tooltip>
</template>

<script>
import { defineComponent } from 'vue'
import { ElTooltip } from 'element-plus'

import { isTouchScreen } from '@/utils/device'

export default defineComponent({
  name: 'Tooltip',
  components: {
    ElTooltip,
  },
  props: {
    class: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    placement: {
      type: String,
      default: () => 'bottom',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    manual: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    className: function () {
      return this.class
    },
    isTouchScreen() {
      return isTouchScreen()
    },
  },
})
</script>
