<template>
  <div
    class="font-semibold text-secondary-700 truncate select-none"
    :class="{
      'text-lg leading-5': layout === 'list',
      'text-lg leading-5': layout === 'default',
      'text-xl text-secondary-900': layout === 'title',
    }"
  >
    <tooltip :title="name">
      <span> {{ name }}</span>
    </tooltip>
  </div>
  <div
    class="font-medium text-secondary flex items-center justify-between"
    :class="{
      'text-sm leading-4': layout === 'grid',
      'text-base leading-5': layout === 'list',
    }"
  >
    <div class="flex-initial truncate flex items-center">
      <div class="leading-0" v-if="!hideIcons">
        <tooltip
          :title="$t(icon.tooltip)"
          v-for="icon in icons"
          :key="icon.tooltip"
        >
          <icon :icon="icon.icon" class="rn-ion-icon text-lg" />
        </tooltip>
      </div>

      <tooltip :title="project?.slug">
        <span class="select-none flex-initial truncate">
          {{ project?.slug }}
        </span>
      </tooltip>
    </div>

    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  cloudDownloadOutline,
  cloudDoneOutline,
  eyeOutline,
  locationOutline,
} from 'ionicons/icons'

import Tooltip from '@/components/atoms/Tooltip'
import Icon from '@/components/atoms/Icon'

import { prettyName, isGeoreferenced } from '@/utils/project'

export default defineComponent({
  components: {
    Tooltip,
    Icon,
  },
  setup() {
    return {
      eyeOutline,
      locationOutline,
      cloudDownloadOutline,
      cloudDoneOutline,
    }
  },
  props: {
    project: {
      type: Object,
    },
    offlineStatus: {
      type: String,
      default: '',
    },
    layout: {
      type: String,
      default: 'default',
    },
    hideIcons: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    icons() {
      return this.possibleIcons.filter(icon => icon.visible)
    },
    name() {
      return prettyName(this.project)
    },
    possibleIcons() {
      return [
        {
          tooltip: 'projects.offline.inProgress',
          icon: cloudDownloadOutline,
          visible: this.offlineStatus === 'inProgress',
        },
        {
          tooltip: 'projects.offline.ready',
          icon: cloudDoneOutline,
          visible: this.offlineStatus === 'ready',
        },
        {
          tooltip: 'projects.public',
          icon: eyeOutline,
          visible: this.project?.status === 'public',
        },
        {
          tooltip: 'projects.georeferenced',
          icon: locationOutline,
          visible: isGeoreferenced(this.project),
        },
      ]
    },
  },
  methods: {
    onRequestAccess() {
      this.sendRequestAccess({ project: this.project })
      //event.stopPropagation()
    },

    requestDisabled() {
      return this.project && this.project.access.status != 1
    },

    requestVisible() {
      return this.project && this.project.access.status != 0
    },
  },
})
</script>

<style scoped>
.rn-image {
  transform: scale(1);
  transition: linear 0.2s all;
}

.rn-container:hover .rn-image {
  transform: scale(1.1);
}

/* https://stackoverflow.com/questions/66484943/how-to-change-title-of-an-ion-icon */
.rn-ion-icon {
  pointer-events: none;
}
</style>
